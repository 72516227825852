import { makeObservable, observable, action } from "mobx";
import { fromBase64 } from "b64-lite";
import Cookies from "js-cookie";

import { PublicData } from "./types";
import { COOKIE_PUBLIC_DATA_TOKEN } from "./constants";

function parsePublicDataToken(token: string) {
  const [publicDataStr] = fromBase64(token).split(";");
  try {
    const publicData: PublicData = JSON.parse(publicDataStr);
    return {
      publicData
    };
  } catch (error) {
    throw new Error(`Failed to parse publicDataStr: ${publicDataStr}`);
  }
}

class PublicDataStore {
  private readonly eventKey = "ePrev-publicDataUpdated";
  private readonly emptyPublicData: PublicData = { userId: null };
  private getToken() {
    return Cookies.get(COOKIE_PUBLIC_DATA_TOKEN);
  }

  state = this.getData();

  constructor() {
    makeObservable(this, {
      state: observable,
      updateState: action,
      clear: action
    });

    if (typeof window !== "undefined") {
      this.updateState(undefined, false);
      window.addEventListener("storage", (event) => {
        if (event.key === this.eventKey) {
          this.updateState(undefined, false);
        }
      });
    }
  }

  updateState = (value?: PublicData, fireEvent = true) => {
    if (fireEvent) {
      localStorage.setItem(this.eventKey, Date.now().toString());
    }

    this.state = value ?? this.getData();
  };

  getData() {
    const publicDataToken = this.getToken();
    if (!publicDataToken) return this.emptyPublicData;

    const { publicData } = parsePublicDataToken(publicDataToken);
    return publicData;
  }

  clear() {
    Cookies.remove(COOKIE_PUBLIC_DATA_TOKEN);
    this.updateState(this.emptyPublicData);
  }
}

export const publicDataStore = new PublicDataStore();
