import { useState, useEffect, useRef } from "react";

export function useDelayedRender(delay = 750) {
  const [rendered, setRendered] = useState(false);
  const timer = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    timer.current = setTimeout(() => {
      setRendered(true);
    }, delay);

    return () => {
      clearTimeout(timer.current);
    };
  }, [delay]);

  return { rendered };
}
