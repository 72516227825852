import { Player, Controls } from "@lottiefiles/react-lottie-player";

import "./styles.css";
import animationDataUrl from "./data.json?url";

export function LoaderLottie() {
  return (
    <Player autoplay loop src={animationDataUrl} style={{ height: "100%", width: "100%" }}>
      <Controls visible={false} />
    </Player>
  );
}
