export function removeLineBreaks(value: string) {
  return value.replace(/(\r\n|\n|\r)/gm, "");
}

const htmlEntityMap = {
  "&": "&amp;",
  "<": "&lt;",
  ">": "&gt;",
  '"': "&quot;",
  "'": "&#39;",
  "/": "&#x2F;",
  "`": "&#x60;",
  "=": "&#x3D;"
};
export function escapeHtml(value: string) {
  return String(value).replace(/[&<>"'`=\/]/g, (s) => {
    return htmlEntityMap[s];
  });
}

export function safeHtmlString(value: string) {
  return escapeHtml(removeLineBreaks(value));
}
