import { useQuery } from "@tanstack/react-query";

import { http } from "../../lib/http";

export function useCommunication() {
  return useQuery(
    ["communication"],
    () =>
      http
        .get(`communication`, {
          headers: {
            "Cache-Control": process.env.NODE_ENV === "development" ? "no-cache" : `max-age=${30 * 60}` // cache pendant 30 minutes
          }
        })
        .json<{
          active: boolean;
          msg: { fr: string; en: string };
        }>(),
    { staleTime: Infinity }
  );
}
