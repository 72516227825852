import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ExclamationIcon,
  TrashIcon,
  PhotographIcon,
  DownloadIcon,
  FolderDownloadIcon,
  DocumentDownloadIcon
} from "@heroicons/react/outline";
import cx from "classnames";

import "./upload.css";
import attrAccept from "./attr-accept";

export interface UploadProps {
  title?: string;
  message?: string;
  typeMessage?: string;
  iconType?: "document" | "folder" | "img" | "any";
  onChange: (file: File) => void;
  defaultFile?: File;
  accept?: string;
  className?: string;
  style?: React.CSSProperties;
}

export { ajaxUpload } from "./ajax-upload";

export const Upload: React.FC<UploadProps> = function Upload({
  title,
  message,
  typeMessage,
  iconType,
  onChange,
  defaultFile,
  accept,
  className,
  style
}) {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>();
  const [isHover, setIsHover] = useState(false);
  const [isDragHovering, setIsDragHovering] = useState(false);
  const [file, setFile] = useState(defaultFile);
  const [msgError, setMsgError] = useState("");

  function onFileDrop(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    if (event.dataTransfer.files?.length > 0) {
      uploadFile(event.dataTransfer.files[0]);
    }
    setIsDragHovering(false);
  }

  function onFileOver(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    setIsDragHovering(true);
  }

  function onFileLeave(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    setIsDragHovering(false);
  }

  function onClickTriggered() {
    if (ref?.current) {
      ref.current.click();
    }
  }

  function onHandleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;
    if (files.length > 0) {
      uploadFile(files[0]);
    }
  }

  function uploadFile(file: File) {
    const acceptedFile = attrAccept(file, accept);
    if (acceptedFile) {
      setFile(file);
      onChange(file);
      setMsgError("");
    } else {
      setMsgError(t("common.upload.ext-error"));
    }
  }

  function resetFile() {
    setFile(null);
    setMsgError("");
    onChange(null);
  }

  return (
    <div className={cx("upload", { [className]: className })} style={style}>
      <label className={cx("upload-title")}>{!title ? t("common.upload.doc") : title}</label>

      <div
        className={cx("upload-container", { "upload-hover": isHover, "upload-isdragging": isDragHovering })}
        onClick={onClickTriggered}
        onDrop={onFileDrop}
        onDragOver={onFileOver}
        onDragLeave={onFileLeave}
        onMouseOver={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <div className="upload-zone">
          {iconsTypesMap[iconType]}
          <input
            ref={ref}
            id="file-upload"
            name="file-upload"
            type="file"
            onChange={onHandleChange}
            accept={accept}
          />
          <p className={cx("upload-msg", { "upload-hover": isHover || isDragHovering })}>
            {isDragHovering
              ? t("common.upload.drop-file-hint")
              : message
              ? message
              : t("common.upload.drop-file")}
          </p>
          <p className={cx("upload-sub-msg", { "upload-hover": isHover || isDragHovering })}>
            {isDragHovering
              ? t("common.upload.waiting-file")
              : typeMessage
              ? typeMessage
              : t("common.upload.default-size")}
          </p>
        </div>
      </div>
      {file && (
        <div className={cx("upload-file")}>
          <span>{file?.name}</span>
          <TrashIcon className="upload-icon-small upload-icon-trash" onClick={resetFile} />
        </div>
      )}
      {msgError && (
        <div className={cx("upload-msg-error")}>
          <ExclamationIcon className="upload-icon-small" onClick={resetFile} />
          <span>{msgError}</span>
        </div>
      )}
    </div>
  );
};

Upload.defaultProps = {
  iconType: "any"
};

const iconsTypesMap = {
  document: <DocumentDownloadIcon className="upload-icon" />,
  folder: <FolderDownloadIcon className="upload-icon" />,
  img: <PhotographIcon className="upload-icon" />,
  any: <DownloadIcon className="upload-icon" />
};
