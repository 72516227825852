import { SVGProps, Ref, forwardRef } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" id="eprev-logo" viewBox="0 0 393.16 109.34" ref={ref} {...props}>
    <g id="Calque_1-2">
      <path
        d="M103.77 31.81c.77 1.62.84 3.27.21 4.95-.63 1.62-1.76 2.81-3.37 3.58-9.83 4.42-22.75 10.25-38.77 17.49-19.88 8.99-33.99 15.35-42.35 19.07 9.83 12.64 21.45 18.96 34.87 18.96 8.92 0 17-2.63 24.23-7.9 6.95-5.06 11.83-11.66 14.64-19.81 1.05-2.95 3.16-4.42 6.32-4.42 2.25 0 4.04.91 5.37 2.74 1.26 1.83 1.51 3.79.74 5.9-3.72 10.75-10.22 19.46-19.49 26.13-9.55 6.81-20.16 10.22-31.82 10.22-15.03 0-27.85-5.3-38.45-15.91C5.3 82.21 0 69.39 0 54.36s5.3-27.85 15.91-38.45C26.51 5.3 39.33 0 54.36 0c10.82 0 20.72 2.95 29.71 8.85 8.78 5.76 15.34 13.42 19.7 22.97zM54.36 13.38c-11.38 0-21.11 4.04-29.18 12.12-8.01 8.08-12.01 17.8-12.01 29.18 0 2.04.35 5.44 1.05 10.22l74.27-33.61c-9.97-11.94-21.35-17.91-34.13-17.91zM250.5 12.68h54.55v6.34H250.5z"
        fill="rgb(var(--primary-color-500))"
        strokeWidth={0}
      />
      <path
        d="M211.4 45.35V27.64h-7.45v81.08h7.77V66.47c0-10.04 2.54-17.89 7.61-23.53 5.07-5.64 12.11-8.46 21.12-8.46l1.86.15v-7.61c-7.56 0-13.98 1.58-19.26 4.74-5.28 3.16-9.16 7.69-11.65 13.59zm86.17-13.12c-5.85-3.47-12.45-5.2-19.8-5.2s-13.98 1.76-19.88 5.28c-5.9 3.52-10.54 8.41-13.9 14.68-3.37 6.27-5.05 13.33-5.05 21.2s1.79 15.07 5.36 21.28c3.57 6.21 8.52 11.08 14.83 14.6 6.31 3.52 13.51 5.28 21.59 5.28 6.21 0 11.98-1.14 17.32-3.42 5.33-2.28 9.76-5.54 13.28-9.78l-4.5-5.13c-3 3.73-6.76 6.55-11.26 8.46-4.5 1.92-9.4 2.87-14.68 2.87-6.42 0-12.19-1.34-17.32-4.04-5.13-2.69-9.19-6.5-12.19-11.42s-4.61-10.53-4.81-16.85h69.74l.16-2.17c0-7.87-1.68-14.91-5.05-21.12-3.37-6.21-7.97-11.05-13.82-14.52zM246.7 63.99c.41-5.8 1.99-11 4.74-15.61 2.74-4.61 6.39-8.18 10.95-10.72 4.55-2.54 9.68-3.81 15.38-3.81s10.82 1.3 15.38 3.88c4.56 2.59 8.23 6.16 11.03 10.72 2.8 4.56 4.35 9.73 4.66 15.53h-62.13zM385.24 27.65l-32.62 72.53-32.3-72.53h-8.23l36.5 81.07h7.92l36.65-81.07h-7.92zM183.93 9.7C176.16 3.24 165.44 0 151.78 0H112.8v108.72h7.91V73.15h31.07c13.66 0 24.38-3.24 32.15-9.71 7.76-6.46 11.64-15.4 11.64-26.78s-3.88-20.48-11.64-26.96zm-5.6 48.7c-6.1 5.08-14.95 7.61-26.55 7.61h-31.07V47.72c-.08.04-.18.08-.26.12l.14-.12.12-35.74V7.14h31.07c11.6 0 20.45 2.56 26.55 7.69 6.11 5.12 9.17 12.4 9.17 21.82s-3.06 16.66-9.17 21.74z"
        fill="#111827"
        strokeWidth={0}
      />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
export { ForwardRef as EPrevLogo };
