import { Navigate } from "react-router-dom";

import { DefaultRoles, AvailablePermissions } from "~/acl";
import { useAuth } from "~/auth";

interface CanBeLearnerProps {
  redirectTo?: string;
}

export function CanBeLearner({ children, redirectTo = "/" }: React.PropsWithChildren<CanBeLearnerProps>) {
  const { user } = useAuth();

  const canSwitchView = user.role.grants.includes(AvailablePermissions.LearnerAccess);

  if (user.role.identifier === DefaultRoles.Learner || canSwitchView) return <>{children}</>;
  else return <Navigate to={redirectTo} replace />;
}
