import { LoaderLottie } from "./lottie";

import { DelayRender } from "../delay-render";

function Loader() {
  return (
    <div className="relative flex h-[108px] w-[108px] items-center justify-center rounded-full border border-slate-200/50 p-6 shadow-xl">
      <LoaderLottie />
    </div>
  );
}

const FullLoader = () => (
  <div className="fixed inset-0 flex items-center justify-center">
    <Loader />
  </div>
);

export const GlobalLoader = () => (
  <DelayRender>
    <FullLoader />
  </DelayRender>
);

export const EprevLoader = () => (
  <DelayRender>
    <Loader />
  </DelayRender>
);
