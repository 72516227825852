import { useState } from "react";

import { useAuth } from "~/auth";
import { EPrevLogo } from "~/components";

import { useOrganizationLogo } from "../../hooks/use-org-logo";

interface OrganizationLogoProps {
  className?: string;
  style?: React.CSSProperties;
}

export function OrganizationLogo(props: OrganizationLogoProps) {
  const { user } = useAuth();
  const elementId = user ? user.hierarchyElementId : null;

  return <OrganizationLogo2 elementId={elementId} {...props} />;
}

interface OrganizationLogo2Props extends OrganizationLogoProps {
  elementId: number;
}

export function OrganizationLogo2({ elementId, ...props }: OrganizationLogo2Props) {
  const [orgLogoErrored, setOrglogoErrored] = useState(false);

  const orgLogo = useOrganizationLogo(elementId);
  if (orgLogo && !orgLogoErrored) {
    return (
      <img
        onError={() => {
          setOrglogoErrored(true);
        }}
        src={orgLogo}
        {...props}
        alt="logo EPrev"
      />
    );
  }

  return <EPrevLogo {...props} />;
}
