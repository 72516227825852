import ky from "ky";

import { getAntiCSRFToken } from "../auth/csrf";

export const http = ky.extend({
  prefixUrl: "/api",
  hooks: {
    beforeRequest: [
      (request) => {
        if (request.method !== "GET" && request.method !== "OPTIONS") {
          request.headers.set("anti-csrf", getAntiCSRFToken());
        }
      }
    ]
  },
  retry: 0,
  timeout: 1000 * 45 // 45 secondes
});
