import { useMemo } from "react";

import { useCurrentOrg } from "../queries/use-current-org";

export function useOrganizationLogo(elementId?: number) {
  const { data: currentOrg } = useCurrentOrg();

  const url = useMemo(() => {
    if (elementId) return makeUrl(elementId);
    if (currentOrg && currentOrg.logo) return makeUrl(currentOrg.id);

    return null;
  }, [currentOrg, elementId]);

  return url;
}

const makeUrl = (elementId: number) => `/api/element/${elementId}/logo`;
