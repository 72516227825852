import { Route, Navigate } from "react-router-dom";

import { useAuth } from "../../auth";

export interface ProtectedRouteProps {
  path: string;
  element?: React.ReactElement;
  redirectTo?: string;
}

export function ProtectedRoute({ path, element, redirectTo = "/login" }: ProtectedRouteProps) {
  const { user } = useAuth();

  if (!user) return <Navigate to={redirectTo} replace />;

  // Utilisateur connecté qui doit changé son mot de passe
  if (user && user.requireNewPassword && path !== "/new-password")
    return <Navigate to="/new-password" replace />;

  // Utilisateur connecté, mais n'a pas complété les champs supplémentaires
  if (user && user.completeFirstConnection && !user.requireNewPassword && path !== "/complete-extrafields")
    return <Navigate to="/complete-extrafields" replace />;

  // Utlisateur connecté, sur la page de changement de mot de passe ou de complétion des champs supplémentaires, sans avoir besoin de le faire
  // => redirection vers la page d'accueil
  if (
    user &&
    !user.requireNewPassword &&
    !user.completeFirstConnection &&
    (path === "/new-password" || path === "/complete-extrafields")
  ) {
    return <Navigate to="/" replace />;
  }

  return <Route path={path} element={element} />;
}
