import React, { useState, useCallback, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "@2jprocess/carton-ui";
import { ExclamationIcon, CheckCircleIcon } from "@heroicons/react/outline";
import { ClockIcon } from "@heroicons/react/solid";
import { m, AnimatePresence } from "framer-motion";
import cx from "classnames";

import "./modal.css";

type BaseModalProps = React.PropsWithChildren<{
  isOpen: boolean;
  intent: "warning" | "danger" | "success";
  title: string;
  message?: React.ReactNode;
  hideCloseButton?: boolean;
  shouldCloseOnBlur?: boolean;
  shouldCloseOnEsc?: boolean;
  txtSkipCountdown?: string;
  txtCancel?: string;
  txtOk?: string;
  onOk?: () => void;
  onCancel: () => void;
  startCountdown?: number;
  isLoading?: boolean;
}>;

export function BaseModal({
  title,
  intent,
  message,
  startCountdown,
  isOpen,
  hideCloseButton,
  shouldCloseOnEsc,
  shouldCloseOnBlur,
  txtCancel,
  txtOk,
  txtSkipCountdown,
  onCancel,
  onOk,
  isLoading
}: BaseModalProps) {
  const [countdown, setCountdown] = useState<number | null>(null);
  const countdownRef = useRef<NodeJS.Timeout>();

  const handleOk = useCallback(() => {
    if (!startCountdown && typeof onOk === "function") onOk();

    if (startCountdown) {
      setCountdown(startCountdown);
      countdownRef.current = setInterval(() => {
        setCountdown((state) => state - 1);
      }, 1000);
    }
  }, [onOk, startCountdown]);

  const cancelCountdown = useCallback(() => {
    if (startCountdown > 0 && countdownRef.current) {
      setCountdown(null);
      clearInterval(countdownRef.current);
      countdownRef.current = undefined;
    }
  }, [startCountdown]);

  const skipCountdown = useCallback(() => {
    setCountdown(0);
  }, []);

  useEffect(() => {
    cancelCountdown();
  }, [cancelCountdown, isOpen, startCountdown]);

  useEffect(() => {
    return () => cancelCountdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      cancelCountdown();
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          if (typeof onOk === "function") onOk();
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown]);

  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      hideCloseButton={hideCloseButton}
      shouldCloseOnBlur={shouldCloseOnBlur}
      shouldCloseOnEsc={shouldCloseOnEsc}
      actions={[
        <OkButton
          countdown={countdown}
          handleOk={handleOk}
          skipCountdown={skipCountdown}
          intent={intent}
          txtSkipCountdown={txtSkipCountdown || t("common.buttons.skip")}
          isLoading={isLoading}
        >
          {txtOk || t("common.buttons.ok")}
        </OkButton>,
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onCancel();
          }}
          key="cancel"
        >
          {txtCancel || t("common.buttons.cancel")}
        </Button>
      ]}
    >
      <div className={cx("modal-wrapper", { "modal-wrapper-empty": !message })}>
        <div className={cx("modal-container-icon", `modal-container-icon-${intent}`)}>
          {iconsIntentMap[intent]}
        </div>
        <div className="modal-content">
          {title && <h3 className="modal-title">{title}</h3>}
          {message && <div className="modal-message">{message}</div>}
        </div>
      </div>
    </Modal>
  );
}

BaseModal.defaultProps = {
  hideCloseButton: false,
  shouldCloseOnEsc: true,
  shouldCloseOnBlur: true,
  isLoading: false
};

type OkButtonProps = React.PropsWithChildren<{
  txtSkipCountdown: string;
  countdown: null | number;
  intent: "warning" | "danger" | "success";
  handleOk: () => void;
  skipCountdown: () => void;
  isLoading: boolean;
}>;

function OkButton({
  intent,
  handleOk,
  children,
  countdown,
  skipCountdown,
  txtSkipCountdown,
  isLoading
}: OkButtonProps) {
  const intentValue = intent === "danger" || intent === "success" ? intent : undefined;

  if (countdown === null)
    return (
      <Button
        onClick={(e) => {
          e.stopPropagation();
          handleOk();
        }}
        appearance="primary"
        intent={intentValue}
        key="confirm"
        isLoading={isLoading}
      >
        {children}
      </Button>
    );

  return (
    <div className={cx("modal-countdown", `modal-countdown-${intent}`)}>
      <div className="modal-countdown-value">
        <AnimatePresence>
          <m.span
            key={countdown || -1}
            exit={{ y: 35, opacity: 0, position: "absolute" }}
            initial={{ y: -25, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              ease: "easeOut",
              duration: 1
            }}
          >
            {countdown}
          </m.span>
        </AnimatePresence>
      </div>
      <Button
        icon={<ClockIcon />}
        iconPos="right"
        appearance="primary"
        intent={intentValue}
        onClick={(e) => {
          e.stopPropagation();
          skipCountdown();
        }}
      >
        {txtSkipCountdown}
      </Button>
    </div>
  );
}

const iconsIntentMap = {
  danger: <ExclamationIcon className="modal-icon" />,
  success: <CheckCircleIcon className="modal-icon" />,
  warning: <ExclamationIcon className="modal-icon" />
};

type IntentModalProps = Omit<BaseModalProps, "intent">;

export const WarningModal = (props: IntentModalProps) => <BaseModal intent="warning" {...props} />;
export const SuccessModal = (props: IntentModalProps) => <BaseModal intent="success" {...props} />;
export const DangerModal = (props: IntentModalProps) => <BaseModal intent="danger" {...props} />;
