import { createContext, useCallback, useEffect, useContext, useMemo } from "react";
import { useQueryClient, useQuery } from "@tanstack/react-query";

import { publicDataStore } from "./public-data";
import { User } from "./types";
import { getAntiCSRFToken } from "./csrf";

type Path = number[];
type Callback = () => void;
type SignOut = (cb?: Callback, fireEvent?: boolean, redirectUrl?: string) => void;
interface AuthContext {
  user?: User;
  signIn: (cb?: Callback) => void;
  signOut: SignOut;
  currentUserPath: Path | null;
  scopePathToCurrentUser: (path: string) => Path | null;
}

// Permet de déconnecter sur tous les onglets
const LOGOUT_EVENT_KEY = "ePrev-logout";
const REFRESH_INFO_KEY = "ePrev-refresh-info";
const AuthContext = createContext<AuthContext | null>(null);

export function AuthProvider({ children }: React.PropsWithChildren<{}>) {
  const query = useQuery({
    queryKey: ["me"],
    queryFn: getCurrentUser,
    retry: false,
    staleTime: 1000 * 60 * 15 // 15min
  });

  const queryClient = useQueryClient();

  const signIn = useCallback(
    async (cb?: Callback) => {
      await query.refetch({ cancelRefetch: true });

      if (cb && typeof cb === "function") cb();
    },
    [query]
  );

  const redirectTo = useMemo(() => {
    const fromET = query.data?.hierarchy?.version === "ET";
    const fromAdmin = query.data?.hierarchy === null;

    if (fromAdmin) return "/admin/login";
    else if (fromET) return "/et/login";
    else return "/login";
  }, [query.data?.hierarchy]);

  const signOut = useCallback(
    async (cb?: Callback, fireEvent = true, redirectUrl = redirectTo) => {
      publicDataStore.clear();

      if (fireEvent) {
        await fetch("/api/logout", {
          method: "POST",
          headers: {
            "anti-csrf": getAntiCSRFToken()
          }
        });
      }

      queryClient.invalidateQueries(["me"]);
      queryClient.clear();

      window.localStorage.removeItem(REFRESH_INFO_KEY);

      if (cb && typeof cb === "function") cb();
      if (fireEvent) localStorage.setItem(LOGOUT_EVENT_KEY, Date.now().toString());

      window.location.href = redirectUrl;
    },
    [queryClient, redirectTo]
  );

  const currentPath: Path | null = useMemo(() => {
    if (!query.data) return null;
    if (!query.data.paths || query.data.paths.length === 0) return null;

    if (query.data.paths.length === 1) return query.data.paths[0].map((p) => p.id);

    const path = query.data.paths.find((p) => p.some(({ id }) => id === query.data.hierarchyElementId));
    if (!path) return null;

    return path.map((p) => p.id);
  }, [query.data]);

  const scopePathToCurrentUser = useCallback(
    (path: string) => {
      if (!currentPath) return pathStringToArray(path);

      const currentUserElementId = currentPath[currentPath.length - 1];
      const pathToScope = pathStringToArray(path);

      const filteredElements: number[] = [];
      for (const id of pathToScope.reverse()) {
        if (id === currentUserElementId) {
          filteredElements.push(id);
          break;
        }

        filteredElements.push(id);
      }

      return filteredElements.reverse();
    },
    [currentPath]
  );

  useEffect(() => {
    function handler(ev: StorageEvent) {
      if (ev.key === LOGOUT_EVENT_KEY) signOut(null, false);
    }

    window.addEventListener("storage", handler);
    return () => {
      window.removeEventListener("storage", handler);
    };
  }, [signOut]);

  if (query.isLoading) return null;

  return (
    <AuthContext.Provider
      value={{ user: query.data, signIn, signOut, currentUserPath: currentPath, scopePathToCurrentUser }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

async function getCurrentUser(): Promise<User | null> {
  const response = await fetch("/api/me");
  if (response.ok) {
    const body = await response.json();
    return {
      id: body.id,
      username: body.username,
      lastname: body.lastname,
      firstname: body.firstname,
      roleId: body.roleId,
      role: {
        ...body.role,
        grants: body.role.grants.map(({ permissionId }) => permissionId),
        managedRoleIds: body.role.roleManagement.map(({ managedRoleId }) => managedRoleId)
      },
      extrafields: body.extrafields,
      email: body.email,
      cgu: body.cgu,
      requireNewPassword: body.requireNewPassword,
      completeFirstConnection: body.completeFirstConnection,
      hierarchy: body.hierarchy,
      hierarchyElementId: body.hierarchyElementId,
      paths: body.paths
    };
  }

  return null;
}
// & { preferences?: { viewAllByDefault: boolean } }
//   ...(body.preferences && { viewAllByDefault: body.preferences.viewAllByDefault })
function pathStringToArray(path: string) {
  return path.split(".").map((p) => parseInt(p, 10));
}
