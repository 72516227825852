import { Navigate } from "react-router-dom";

import { ROLES_2J, DefaultRoles } from "../types";
import { useAuth } from "../../auth";

export function RoleBalancer() {
  const { user } = useAuth();

  if (user.role.identifier === DefaultRoles.Learner) return <Navigate to="/learner" replace />;
  if (ROLES_2J.includes(user.role.identifier as DefaultRoles)) return <Navigate to="/admin" replace />;

  return <Navigate to="/org/" replace />;
}
