/**  RC FILE ATR-ACCEPT @09-03-2021 */
function endsWith(str: string, suffix: string) {
  return str.indexOf(suffix, str.length - suffix.length) !== -1;
}

export default (file: File, acceptedFiles: string) => {
  if (file && acceptedFiles) {
    const acceptedFilesArray = acceptedFiles.split(",");
    const fileName = file.name || "";
    const mimeType = file.type || "";
    const baseMimeType = mimeType.replace(/\/.*$/, "");

    return acceptedFilesArray.some((type) => {
      const validType = type.trim();
      if (validType.charAt(0) === ".") {
        return endsWith(fileName.toLowerCase(), validType.toLowerCase());
      }
      if (/\/\*$/.test(validType)) {
        // This is something like a image/* mime type
        return baseMimeType === validType.replace(/\/.*$/, "");
      }
      return mimeType === validType;
    });
  }
  return true;
};
