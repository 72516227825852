import React from "react";
import ReactDOM from "react-dom";
import { ErrorBoundary } from "react-error-boundary";
import duration from "dayjs/plugin/duration";
import dayjs from "dayjs";
import { LazyMotion } from "framer-motion";
import { enableAllPlugins } from "immer";
import { initializeI18n } from "@eprev/i18n";
import { I18nextProvider } from "react-i18next";

import "tailwindcss/tailwind.css";
import "@2jprocess/carton-ui/dist/index.css";

import "./global-styles.css";
import { App } from "./app";
import { GlobalError } from "./components/errors/global-error";
import { GlobalLoader } from "./components/global-loader";
import { GlobalProgressBar } from "./components/global-progress-bar";

dayjs.extend(duration);
enableAllPlugins();
const i18n = initializeI18n();

const loadAnimations = () => import("./animations").then((res) => res.default);

ReactDOM.render(
  <React.StrictMode>
    <GlobalProgressBar>
      <ErrorBoundary FallbackComponent={GlobalError}>
        <React.Suspense fallback={<GlobalLoader />}>
          <LazyMotion strict features={loadAnimations}>
            <I18nextProvider i18n={i18n}>
              <App />
            </I18nextProvider>
          </LazyMotion>
        </React.Suspense>
      </ErrorBoundary>
    </GlobalProgressBar>
  </React.StrictMode>,
  document.getElementById("root")
);
