import { useIsFetching } from "@tanstack/react-query";
import { AnimatePresence, m } from "framer-motion";

import { useDebounce } from "~/hooks";

import { LoaderLottie } from "./lottie";

interface ActivityLoaderProps {
  isLoading: boolean;
}

export function ActivityLoader({ isLoading }: ActivityLoaderProps) {
  return (
    <AnimatePresence>
      {isLoading && (
        <m.div
          initial={{ y: -10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          className="fixed bottom-4 right-4 z-50 rounded border border-primary-400 bg-white p-2 shadow-md"
        >
          <div className="w-8">
            <LoaderLottie />
          </div>
        </m.div>
      )}
    </AnimatePresence>
  );
}

export function GlobalActivityLoader() {
  const isFetching = useIsFetching();
  const isLoading = useDebounce(isFetching > 0, 750);

  return <ActivityLoader isLoading={isLoading} />;
}
