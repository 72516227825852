import cx from "classnames";
import { Link } from "react-router-dom";
import { createContext, useContext, isValidElement, cloneElement } from "react";
import { ViewGridIcon, ViewListIcon, TrashIcon } from "@heroicons/react/solid";
import { Tooltip, Button } from "@2jprocess/carton-ui";
import { useLocalStorageValue } from "@2jprocess/carton-ui-hooks";

import { CoursesGrid } from "./course-item-body";

import { Switch } from "../switch";

export function Courses({ children }: React.PropsWithChildren<{}>) {
  const { viewMode: mode } = useContext(CourseViewModeContext);

  return (
    <ul
      role="list"
      className={cx({
        "space-y-12 sm:grid sm:gap-6 sm:space-y-0 lg:gap-8": mode === "grid",
        "grid-cols-[repeat(auto-fill,_minmax(18rem,_1fr))]": mode === "grid",
        "divide-y divide-gray-200 overflow-hidden bg-white shadow sm:rounded-md": mode === "list"
      })}
    >
      {children}
    </ul>
  );
}

export type DeleteCourse = {
  label: string;
  cb: () => void;
};

export interface CourseItemProps {
  to: string;
  course: {
    id: number;
    title: string;
    identifier: string;
    description?: string;
    img?: string;
    folder?: string;
    version: number;
    totalTime?: string;
    numberOfLessons?: number;
    tags?: React.ReactNode[];
    learningPathName?: string;
  };
  deleteCourse?: DeleteCourse;
}

function CourseItem({ course, to, deleteCourse, children }: React.PropsWithChildren<CourseItemProps>) {
  const { viewMode: mode } = useContext(CourseViewModeContext);

  return (
    <li
      className={cx("cursor-pointer overflow-hidden bg-white", {
        "flex transform flex-col rounded-lg shadow transition-shadow hover:-translate-y-0.5 hover:shadow-md":
          mode === "grid",
        relative: deleteCourse
      })}
    >
      {mode === "grid" && deleteCourse && (
        <Tooltip title={deleteCourse.label}>
          <Button
            size="xs"
            onClick={deleteCourse.cb}
            className="absolute right-2 top-2 z-10"
            icon={<TrashIcon className="text-red-500" />}
          />
        </Tooltip>
      )}
      <Link
        to={to}
        className={cx({
          "block hover:bg-gray-50": mode === "list",
          "flex flex-1 flex-col": mode === "grid"
        })}
      >
        {isValidElement(children) ? (
          cloneElement<any>(children, { mode })
        ) : (
          <CoursesGrid {...{ mode, course, deleteCourse }} />
        )}
      </Link>
    </li>
  );
}

type CourseViewMode = "grid" | "list";
interface CourseOpts {
  displayTags?: boolean;
  displayVersion?: boolean;
  displayDescription?: boolean;
  displayInfos?: boolean;
}

export const CourseViewModeContext = createContext<{
  viewMode: CourseViewMode;
  setViewMode: React.Dispatch<React.SetStateAction<CourseViewMode>>;
  opts: CourseOpts;
}>(null);

function CourseViewModeProvider({
  children,
  opts = {},
  defaultViewMode = "grid",
  storageKey = "course-view-mode"
}: React.PropsWithChildren<{ opts?: CourseOpts; defaultViewMode?: CourseViewMode; storageKey?: string }>) {
  const [viewMode, setViewMode] = useLocalStorageValue<CourseViewMode>(storageKey, defaultViewMode);

  return (
    <CourseViewModeContext.Provider
      value={{
        viewMode,
        setViewMode,
        opts: Object.assign(
          {},
          {
            displayDescription: true,
            displayTags: false,
            displayInfos: true,
            displayVersion: false
          },
          opts
        )
      }}
    >
      {children}
    </CourseViewModeContext.Provider>
  );
}

function CourseViewModeSwitcher() {
  const { viewMode, setViewMode } = useContext(CourseViewModeContext);

  return (
    <Switch activeValue={viewMode} onChange={setViewMode}>
      <Switch.Item icon={ViewGridIcon} value="grid" />
      <Switch.Item icon={ViewListIcon} value="list" />
    </Switch>
  );
}

Courses.Item = CourseItem;
Courses.Switcher = CourseViewModeSwitcher;
Courses.Provider = CourseViewModeProvider;
