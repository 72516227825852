import cx from "classnames";
import React, { useContext, useEffect, useReducer, useRef } from "react";
import { Button, Checkbox, Popover } from "@2jprocess/carton-ui";
import { useId, useIsomorphicEffect } from "@2jprocess/carton-ui-hooks";

import { matchReducer } from "~/utils";

import { Keys } from "./keys.types";

import type { ButtonProps } from "@2jprocess/carton-ui/dist/button";

import "./style.css";

type State = {
  value: Set<string>;
  menuOpen: boolean;
  sections: Set<string>;
  items: Array<{ id: string; value: string; isDisabled: boolean }>;
  activeItemIndex: number | null;
};

enum ActionTypes {
  ChangeItem,
  MenuOpen,
  RegisterSection,
  UnregisterSection,
  RegisterItem,
  UnregisterItem,
  UpdateItem,
  SetActiveItem,
  SetNextItemActive,
  SetPreviousItemActive,
  ClearActiveItem,
  Reset
}

type Actions =
  | { type: ActionTypes.ChangeItem; checked: boolean; value: string }
  | { type: ActionTypes.MenuOpen; menuOpen: boolean }
  | { type: ActionTypes.RegisterSection; id: string }
  | { type: ActionTypes.UnregisterSection; id: string }
  | { type: ActionTypes.RegisterItem; id: string; value: string; isDisabled: boolean }
  | { type: ActionTypes.UpdateItem; id: string; isDisabled: boolean }
  | { type: ActionTypes.UnregisterItem; id: string }
  | { type: ActionTypes.SetActiveItem; id: string }
  | { type: ActionTypes.SetNextItemActive }
  | { type: ActionTypes.SetPreviousItemActive }
  | { type: ActionTypes.ClearActiveItem }
  | { type: ActionTypes.Reset };

const reducers: {
  [P in ActionTypes]: (state: State, action: Extract<Actions, { type: P }>) => State;
} = {
  [ActionTypes.ChangeItem]: (state, action) => {
    const value = new Set(state.value);
    action.checked ? value.add(action.value) : value.delete(action.value);
    return { ...state, value };
  },
  [ActionTypes.MenuOpen]: (state, { menuOpen }) => {
    return { ...state, menuOpen };
  },
  [ActionTypes.RegisterSection]: (state, action) => {
    const sections = new Set(state.sections);
    sections.add(action.id);
    return { ...state, sections };
  },
  [ActionTypes.UnregisterSection]: (state, action) => {
    const sections = new Set(state.sections);
    sections.delete(action.id);
    return { ...state, sections };
  },
  [ActionTypes.RegisterItem]: (state, { id, value, isDisabled }) => {
    const items = state.items.concat({ id, value, isDisabled });
    return { ...state, items };
  },
  [ActionTypes.UpdateItem]: (state, { id, isDisabled }) => {
    const items = [...state.items];
    const index = items.findIndex((item) => item.id === id);
    index > -1 && (items[index].isDisabled = isDisabled);
    return { ...state, items };
  },

  [ActionTypes.UnregisterItem]: (state, action) => {
    const items = state.items.filter((item) => item.id !== action.id);
    const currentActiveItem = state.activeItemIndex !== null ? state.items[state.activeItemIndex] : null;
    const index = items.indexOf(currentActiveItem);
    const activeItemIndex = index > -1 ? index : null;
    return { ...state, items, activeItemIndex };
  },
  [ActionTypes.SetNextItemActive]: (state) => {
    const activeIndex = state.activeItemIndex ?? -1;
    let activeItemIndex = state.items.findIndex((item, idx) => {
      if (idx <= activeIndex) return false;
      return !item.isDisabled;
    });
    if (activeItemIndex == -1) {
      activeItemIndex = state.items.findIndex((item) => !item.isDisabled);
    }
    return {
      ...state,
      activeItemIndex: activeItemIndex === -1 ? null : activeItemIndex
    };
  },
  [ActionTypes.SetPreviousItemActive]: (state) => {
    const activeIndex = state.activeItemIndex ?? -1;
    let activeItemIndex = state.activeItemIndex;
    const reversed = state.items.slice().reverse();
    let idx = reversed.findIndex((item, idx, all) => {
      if (activeIndex !== -1 && all.length - idx - 1 >= activeIndex) return false;
      return !item.isDisabled;
    });
    if (idx === -1) {
      idx = reversed.findIndex((item) => !item.isDisabled);
    }
    activeItemIndex = state.items.length - 1 - idx;
    return {
      ...state,
      activeItemIndex: activeItemIndex === -1 ? null : activeItemIndex
    };
  },
  [ActionTypes.SetActiveItem]: (state, action) => ({
    ...state,
    activeItemIndex: state.items.findIndex((item) => item.id === action.id)
  }),
  [ActionTypes.ClearActiveItem]: (state) => ({
    ...state,
    activeItemIndex: null
  }),
  [ActionTypes.Reset]: (state) => ({
    ...state,
    value: new Set()
  })
};

function stateReducer(state: State, action: Actions) {
  return matchReducer(action.type, reducers, state, action);
}

const MenuContext = React.createContext<ContextMenu>(null);

interface ContextMenu {
  state: State;
  dispatch: React.Dispatch<Actions>;
  menuRef: React.RefObject<HTMLDivElement>;
}
interface Props {
  value: string[];
  onChange: (selected: string[]) => void;
  children: React.ReactElement | React.ReactElement[];
}

/**
 * @example  Exemple
 * @argument value  Array<string>  est un tableau d'id 
 * alternativement, possible d'utiliser DropdownFilters.Check checked:boolean
 * 
 * ```tsx
  <DropdownFilters onChange={onChange} value={value}>
      <DropdownFilters.Trigger>
        <IconButton>
          <DotsVerticalIcon />
        </IconButton>
      </DropdownFilters.Trigger>
      <DropdownFilters.Menu key="menu" className="w-full sm:w-56">
        <DropdownFilters.Section label="Documentation">
          <DropdownFilters.Check checked={true} value="docs-graphql">
            GraphQL API
          </DropdownFilters.Check>
          <DropdownFilters.Check value="docs-hasura">Hasura</DropdownFilters.Check>
        </DropdownFilters.Section>
        <DropdownFilters.Section label="Projets">
          <DropdownFilters.Check value="projects-cra">Create React APP</DropdownFilters.Check>
          <DropdownFilters.Check checked={false} value="projects-react">
            ReactJS
          </DropdownFilters.Check>
          <DropdownFilters.Check checked={false} value="projects-vue">
            VueJS
          </DropdownFilters.Check>
        </DropdownFilters.Section>
      </DropdownFilters.Menu>
    </DropdownFilters>
 * ```
 */
export function DropdownFilters(props: Props) {
  const menuRef: ContextMenu["menuRef"] = useRef();
  const [state, dispatch] = useReducer(stateReducer, {
    value: new Set(props.value || []),
    menuOpen: false,
    sections: new Set(),
    items: []
  } as State);

  useEffect(() => props.onChange && props.onChange([...state.value]), [props, state.value]);

  return (
    <nav>
      <MenuContext.Provider value={{ state, dispatch, menuRef }}>{props.children}</MenuContext.Provider>
    </nav>
  );
}

DropdownFilters.Menu = Menu;
DropdownFilters.Check = MenuCheck;
DropdownFilters.Section = MenuSection;
DropdownFilters.Trigger = MenuTrigger;
DropdownFilters.ResetButton = ResetButton;
DropdownFilters.displayName = "DropdownFilters";

MenuCheck.displayName = "DropdownFilters.Item";
MenuSection.displayName = "DropdownFilters.Section";
MenuTrigger.displayName = "DropdownFilters.Trigger";

// ////////////////////
// MENU SOUS-COMPOSANTS
// ////////////////////

type ResetButtonProps = Partial<
  React.PropsWithChildren<StyleProps> & { appearance: ButtonProps["appearance"] }
>;

function ResetButton({ children, className, style, appearance = "text" }: ResetButtonProps) {
  const { dispatch } = useContext(MenuContext);
  const onClick = () => dispatch({ type: ActionTypes.Reset });

  return (
    <Button onClick={onClick} className={className} style={style} appearance={appearance}>
      {children}
    </Button>
  );
}

type MenuProps = React.PropsWithChildren<StyleProps> & { header?: React.ReactNode };

function Menu({ className, style, children, header }: MenuProps) {
  const id = `carton-ui-dropdown-filter-${useId()}`;
  const { state, dispatch, menuRef } = useContext(MenuContext);
  const isOpen = state.menuOpen;
  const close = () => dispatch({ type: ActionTypes.MenuOpen, menuOpen: false });
  const handleBlur = () => dispatch({ type: ActionTypes.ClearActiveItem });

  const handleKeyDown = (e: React.KeyboardEvent<HTMLUListElement>) => {
    e.preventDefault();

    switch (e.key) {
      case Keys.Tab:
        return;
      case Keys.Escape:
        dispatch({ type: ActionTypes.MenuOpen, menuOpen: false });
        break;
      case Keys.ArrowDown:
        dispatch({ type: ActionTypes.SetNextItemActive });
        break;
      case Keys.ArrowUp:
        dispatch({ type: ActionTypes.SetPreviousItemActive });
        break;
      case Keys.Enter:
      case Keys.Space:
        if (state.activeItemIndex !== null) {
          const { value } = state.items[state.activeItemIndex];
          const isChecked = state.value.has(value);
          dispatch({ type: ActionTypes.ChangeItem, checked: !isChecked, value });
        }
        break;
      default:
        break;
    }
  };

  return (
    <Popover isOpen={isOpen} onClose={close} targetRef={menuRef} placement="bottom-start">
      {header}
      <ul
        id={id}
        role="menu"
        style={style}
        className={cx("menu", { [className]: className })}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        tabIndex={state.activeItemIndex === null ? 0 : -1}
        aria-activedescendant={
          state.activeItemIndex === null ? undefined : state.items[state.activeItemIndex]?.id
        }
      >
        {children}
      </ul>
    </Popover>
  );
}

type MenuTrigger = React.PropsWithChildren<ButtonProps>;

function MenuTrigger(props: MenuTrigger) {
  const id = `carton-ui-dropdown-trigger-${useId()}`;
  const { dispatch, menuRef } = useContext(MenuContext);
  const toggle = () => dispatch({ type: ActionTypes.MenuOpen, menuOpen: true });
  const children = React.cloneElement(props.children as React.ReactElement, { onClick: toggle });
  return (
    <div id={id} ref={menuRef}>
      {children}
    </div>
  );
}

export interface MenuSectionProps {
  label?: React.ReactNode;
  "aria-label"?: string;
}

export function MenuSection({ label, children, ...props }: React.PropsWithChildren<MenuSectionProps>) {
  const id = `carton-ui-menusection-${useId()}`;
  const { state, dispatch } = useContext(MenuContext);

  useIsomorphicEffect(() => {
    dispatch({ type: ActionTypes.RegisterSection, id });
    return () => dispatch({ type: ActionTypes.UnregisterSection, id });
  }, [id]);

  const [first] = state.sections;
  return (
    <>
      {first !== id && <li role="separator" aria-orientation="horizontal" />}
      <li role="presentation">
        {label && (
          <h3 id={id} aria-hidden="true">
            {label}
          </h3>
        )}
        <ul role="group" aria-labelledby={label ? id : undefined} aria-label={props["aria-label"]}>
          {children}
        </ul>
      </li>
    </>
  );
}

interface StyleProps {
  className?: string;
  style?: React.CSSProperties;
}

export interface MenuCheckProps extends StyleProps {
  checked?: boolean;
  isDisabled?: boolean;
  value: string;
  icon?: React.ReactNode;
}

export function MenuCheck({
  children,
  checked: defaultChecked,
  isDisabled = false,
  value,
  style,
  className,
  icon
}: React.PropsWithChildren<MenuCheckProps>) {
  const id = `carton-ui-menuitem-${useId()}`;
  const { state, dispatch } = useContext(MenuContext);
  const checked = state.value.has(value);
  const active = state.activeItemIndex !== null ? state.items[state.activeItemIndex]?.id === id : false;

  useIsomorphicEffect(() => {
    dispatch({ type: ActionTypes.RegisterItem, id, value, isDisabled });
    return () => dispatch({ type: ActionTypes.UnregisterItem, id });
  }, [id, value, isDisabled]);

  // Priorité à defaultChecked sur state.value
  useIsomorphicEffect(() => {
    typeof defaultChecked !== "undefined" &&
      dispatch({ type: ActionTypes.ChangeItem, checked: defaultChecked, value });
  }, [defaultChecked, dispatch, value]);

  useIsomorphicEffect(() => {
    dispatch({ type: ActionTypes.UpdateItem, id, isDisabled });
  }, [dispatch, id, isDisabled]);

  const onChange = (checked: boolean) => dispatch({ type: ActionTypes.ChangeItem, checked, value });
  return (
    <li
      id={id}
      role="menuitem"
      aria-disabled={isDisabled}
      className={cx("menuitem", {
        "menuitem-disabled": isDisabled,
        "menuitem-active": active,
        [className]: className
      })}
      style={style}
    >
      <Checkbox checked={checked} onChange={onChange} isDisabled={isDisabled}>
        {icon && <span className="menuitem-icon">{icon}</span>} {children}
      </Checkbox>
    </li>
  );
}
