import { useSessionStorageValue } from "@2jprocess/carton-ui-hooks";
import { XIcon, ExclamationIcon } from "@heroicons/react/solid";
import { useCurrentLocale } from "@eprev/i18n";
import { useTranslation } from "react-i18next";

import { useCommunication } from "./use-communication";

export function AlertBox() {
  const locale = useCurrentLocale();
  const { t } = useTranslation();
  const [show, setShow] = useSessionStorageValue(`show-alert-box`, true);
  const { data: communication, isLoading } = useCommunication();

  if (isLoading || !communication.active || !show) return null;

  return (
    <div className=" fixed top-0 flex w-full justify-between bg-primary-50 p-4" style={{ zIndex: 9999 }}>
      <div className=" flex items-center space-x-4">
        <div>
          <ExclamationIcon className=" h-6 w-6 text-primary-400" />
        </div>
        <div className="text-sm text-primary-800">
          <h1 className="text-sm font-semibold">{t("communication.title")}</h1>
          <p>{locale === "fr" ? communication.msg.fr : communication.msg.en}</p>
        </div>
      </div>
      <div>
        <button onClick={() => setShow(false)}>
          <XIcon className="h-4 w-4 text-primary-800" onClick={() => setShow(false)} />
        </button>
      </div>
    </div>
  );
}
