import { useTranslation } from "react-i18next";

import { BackgroundImg } from "../../organization/components/brand/background";
import { OrganizationLogo } from "../../organization/components/brand/logo";

export function GlobalError() {
  const { t } = useTranslation();
  return (
    <div className="flex min-h-screen flex-col bg-white lg:relative">
      <div className="flex flex-grow flex-col">
        <main className="flex flex-grow flex-col bg-white">
          <div className="mx-auto flex w-full max-w-7xl flex-grow flex-col px-4 sm:px-6 lg:px-8">
            <div className="shrink-0 pt-10 sm:pt-16">
              <a href="/" className="inline-flex">
                <OrganizationLogo className="h-16 w-auto" />
              </a>
            </div>
            <div className="my-auto shrink-0 py-16 sm:py-32">
              <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                {t("common.error")}
              </h1>
              <p className="mt-2 text-base text-gray-500">{t("common.errors.unknown-msg")}</p>
              <div className="mt-6">
                <a href="/" className="text-base font-medium text-primary-500 hover:text-primary-600">
                  {t("back-home")}
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div className="hidden lg:absolute lg:inset-y-0 lg:right-0 lg:block lg:w-1/2">
        <BackgroundImg className="absolute inset-0 h-full w-full object-cover" />
      </div>
    </div>
  );
}
