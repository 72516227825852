import { useEffect } from "react";
import { useSafeState } from "@2jprocess/carton-ui-hooks";

const isBrowser = typeof window !== "undefined" && typeof document !== "undefined";

export interface NetworkInformation extends EventTarget {
  readonly type: "bluetooth" | "cellular" | "ethernet" | "none" | "wifi" | "wimax" | "other" | "unknown";
}

export interface UseNetworkState {
  online: boolean | undefined;
  previous: boolean | undefined;
  since: Date | undefined;
  type: NetworkInformation["type"] | undefined;
}

type NavigatorWithConnection = Navigator &
  Partial<Record<"connection" | "mozConnection" | "webkitConnection", NetworkInformation>>;
const navigator = isBrowser ? (window.navigator as NavigatorWithConnection) : undefined;

const conn: NetworkInformation | undefined =
  navigator && (navigator.connection || navigator.mozConnection || navigator.webkitConnection);

function getConnectionState(previousState?: UseNetworkState): UseNetworkState {
  const online = navigator?.onLine;
  const previousOnline = previousState?.online;

  return {
    online,
    previous: previousOnline,
    since: online !== previousOnline ? new Date() : previousState?.since,
    type: conn?.type
  };
}

export function useNetworkState(initialState?: UseNetworkState | (() => UseNetworkState)): UseNetworkState {
  const [state, setState] = useSafeState(initialState ?? getConnectionState);

  useEffect(() => {
    const handleStateChange = () => {
      setState(getConnectionState);
    };

    window.addEventListener("online", handleStateChange, { passive: true });
    window.addEventListener("offline", handleStateChange, { passive: true });

    if (conn) {
      conn.addEventListener("change", handleStateChange, { passive: true });
    }

    return () => {
      window.removeEventListener("online", handleStateChange);
      window.removeEventListener("offline", handleStateChange);

      if (conn) {
        conn.removeEventListener("change", handleStateChange);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state;
}
