import cx from "classnames";
import { useState } from "react";

import "./course-img.css";

export interface CourseImgProps {
  id: number;
  image?: string;
  className?: string;
}

export function CourseImg({ className, id, image }: CourseImgProps) {
  const [errored, setErrored] = useState(false);

  if (image && !errored)
    return (
      <img
        onError={() => setErrored(true)}
        src={`/api/course/${id}/image`}
        className={cx("course-img", className)}
      />
    );

  return (
    <div className={cx("group relative flex overflow-hidden bg-zinc-50 ring-1 ring-zinc-200/50", className)}>
      <div className="pointer-events-none">
        <div className="absolute inset-0">
          <svg
            aria-hidden="true"
            className="absolute inset-x-0 inset-y-[-30%] h-[160%] w-full skew-y-[-18deg] fill-black/[0.02] stroke-black/5"
          >
            <defs>
              <pattern id=":R56hdcqla:" width="72" height="56" patternUnits="userSpaceOnUse" x="50%" y="16">
                <path d="M.5 56V.5H72" fill="none"></path>
              </pattern>
            </defs>
            <rect width="100%" height="100%" strokeWidth="0" fill="url(#:R56hdcqla:)"></rect>
            <svg x="50%" y="16" className="overflow-visible">
              <rect strokeWidth="0" width="73" height="57" x="0" y="56"></rect>
              <rect strokeWidth="0" width="73" height="57" x="72" y="168"></rect>
            </svg>
          </svg>
        </div>
        <div className="absolute inset-0 bg-gradient-to-r from-[rgb(var(--primary-color-200))] to-[rgb(var(--primary-color-100))]"></div>
        <div className="absolute inset-0 mix-blend-overlay">
          <svg
            aria-hidden="true"
            className="absolute inset-x-0 inset-y-[-30%] h-[160%] w-full skew-y-[-18deg] fill-black/50 stroke-black/70 "
          >
            <defs>
              <pattern id=":R1d6hdcqla:" width="72" height="56" patternUnits="userSpaceOnUse" x="50%" y="16">
                <path d="M.5 56V.5H72" fill="none"></path>
              </pattern>
            </defs>
            <rect width="100%" height="100%" strokeWidth="0" fill="url(#:R1d6hdcqla:)"></rect>
            <svg x="50%" y="16" className="overflow-visible">
              <rect strokeWidth="0" width="73" height="57" x="0" y="56"></rect>
              <rect strokeWidth="0" width="73" height="57" x="72" y="168"></rect>
            </svg>
          </svg>
        </div>
      </div>
    </div>
  );
}
