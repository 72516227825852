import { useDebounceValue } from "@2jprocess/carton-ui-hooks";

import classes from "./global-progress-bar.module.css";

interface ProgressBarProps {
  show?: boolean;
  debouncedTime?: number;
}

export function ProgressBar({ show = false, debouncedTime = 1500 }: ProgressBarProps) {
  const debouncedShow = useDebounceValue(show, debouncedTime);

  if (!debouncedShow) return null;

  return (
    <div className={classes.globalProgressBar}>
      <div className={classes.globalProgressBarProgress}></div>
    </div>
  );
}
