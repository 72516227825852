import { useMemo } from "react";
import { useSessionStorageValue } from "@2jprocess/carton-ui-hooks";

import { useCurrentOrg } from "../../queries/use-current-org";

interface BackgroundImgProps {
  className?: string;
  style?: React.CSSProperties;
}

export function BackgroundImg({ className, style }: BackgroundImgProps) {
  const { data: currentOrg } = useCurrentOrg();

  const [oldBg] = useSessionStorageValue("org-bg", `/images/bg_${~~(Math.random() * 14) + 1}.jpg`, {
    storeDefaultValue: true
  });

  const bg = useMemo(() => {
    if (currentOrg && currentOrg.backgroundImg) return `/api/element/${currentOrg.id}/background-img`;

    return oldBg;
  }, [currentOrg, oldBg]);

  return <img src={bg} className={className} style={style} />;
}
