import { useReducer, createContext, useContext, useEffect } from "react";
import { useIsomorphicEffect } from "@2jprocess/carton-ui-hooks";
import cx from "classnames";

import { matchReducer } from "../../utils";

interface State {
  activeValue: unknown;
  values: unknown[];
}

enum ActionTypes {
  Register,
  Unregister,
  SetActiveValue
}

type Actions =
  | { type: ActionTypes.Register; value: unknown }
  | { type: ActionTypes.Unregister; value: unknown }
  | { type: ActionTypes.SetActiveValue; value: unknown };

const reducers: { [P in ActionTypes]: (state: State, action: Extract<Actions, { type: P }>) => State } = {
  [ActionTypes.Register]: (state, action) => {
    const values = [...state.values, action.value];

    return { ...state, values };
  },
  [ActionTypes.Unregister]: (state, action) => {
    const idx = state.values.indexOf(action.value);
    const values = [...state.values];

    if (idx !== -1) values.splice(idx, 1);

    return { ...state, values };
  },
  [ActionTypes.SetActiveValue]: (state, action) => ({ ...state, activeValue: action.value })
};

function stateReducer(state: State, action: Actions) {
  return matchReducer(action.type, reducers, state, action);
}

const SwitchContext = createContext<[State, React.Dispatch<Actions>]>(null);

export interface SwitchProps {
  activeValue: unknown;
  onChange: (value: unknown) => void;
  children: [React.ReactElement<SwitchItemProps>, React.ReactElement<SwitchItemProps>];
}

export function Switch({ activeValue, children, onChange }: SwitchProps) {
  const [state, dispatch] = useReducer(stateReducer, {
    activeValue: activeValue,
    values: []
  } as State);

  useEffect(() => {
    if (onChange && typeof onChange === "function") onChange(state.activeValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.activeValue]);

  useEffect(() => {
    dispatch({ type: ActionTypes.SetActiveValue, value: activeValue });
  }, [activeValue]);

  return (
    <SwitchContext.Provider value={[state, dispatch]}>
      <div className="group inline-flex rounded-lg bg-gray-100 p-0.5 hover:bg-gray-200">{children}</div>
    </SwitchContext.Provider>
  );
}
Switch.Item = SwitchItem;

interface BaseProps {
  value: unknown;
  hideChildrenOnMobile?: boolean;
}

interface UsingIconProps extends BaseProps {
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  children?: React.ReactNode;
}

interface UsingChildrenProps extends BaseProps {
  children: React.ReactNode;
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
}

export type SwitchItemProps = XOR<UsingIconProps, UsingChildrenProps>;

function SwitchItem({ value, children, icon: Icon, hideChildrenOnMobile = true }: SwitchItemProps) {
  const [state, dispatch] = useContext(SwitchContext);
  const isActive = state.activeValue === value;

  useIsomorphicEffect(() => {
    dispatch({ type: ActionTypes.Register, value });

    return () => {
      dispatch({ type: ActionTypes.Unregister, value });
    };
  }, [value, dispatch]);

  return (
    <button
      type="button"
      className={cx(
        "flex items-center rounded-md p-1.5 text-sm font-medium text-gray-600 last:ml-0.5 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-100",
        {
          "bg-white shadow-sm ring-1 ring-black ring-opacity-5": isActive,
          "lg:pl-2.5 lg:pr-3.5": Icon && children && hideChildrenOnMobile,
          "pl-2.5 pr-3.5": Icon && children && !hideChildrenOnMobile,
          "px-2": Icon && !children
        }
      )}
      onClick={() => dispatch({ type: ActionTypes.SetActiveValue, value })}
    >
      {Icon && (
        <Icon
          className={cx("h-5 w-5", {
            "text-gray-500 group-hover:text-gray-900": !isActive,
            "text-primary-500": isActive,
            "lg:mr-2": children && hideChildrenOnMobile,
            "mr-2": children && !hideChildrenOnMobile
          })}
        />
      )}
      {children && (
        <span
          className={cx({
            "text-gray-900": isActive,
            "text-gray-600 group-hover:text-gray-900": !isActive,
            "sr-only lg:not-sr-only": hideChildrenOnMobile
          })}
        >
          {children}
        </span>
      )}
    </button>
  );
}
