import React from "react";

import { useDelayedRender } from "../hooks";

export interface DelayedRenderProps {
  children: React.ReactNode;
  enterDelay?: number;
}

export function DelayRender({ children, enterDelay = 750 }: DelayedRenderProps) {
  const { rendered } = useDelayedRender(enterDelay);

  if (!rendered) return null;

  return <>{children}</>;
}
