import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip, Button } from "@2jprocess/carton-ui";
import { TrashIcon, ChevronRightIcon, ClockIcon, BookOpenIcon } from "@heroicons/react/solid";

import { CourseItemProps, CourseViewModeContext, DeleteCourse } from ".";

import { CourseImg } from "./img";

export function CoursesGrid({ mode, course, deleteCourse }) {
  return (
    <>
      {mode === "grid" && <CourseItemGridBody {...course} />}
      {mode === "list" && <CourseItemListBody {...course} deleteCourse={deleteCourse} />}
    </>
  );
}

function CourseItemGridBody({
  id,
  title,
  description,
  img,
  version,
  totalTime,
  learningPathName,
  numberOfLessons,
  tags
}: CourseItemProps["course"]) {
  const { t } = useTranslation();

  const {
    opts: { displayDescription, displayVersion, displayInfos, displayTags }
  } = useContext(CourseViewModeContext);

  return (<>
    <div className="shrink-0 border-b border-gray-200">
      <CourseImg id={id} image={img} className="aspect-[3/2] h-full rounded-t-lg object-cover" />
    </div>
    <div className="flex flex-1 flex-col justify-between space-y-2 p-4">
      <div>
        <h3 title={title} className="line-clamp-2 text-base font-semibold text-gray-900">
          {title}
        </h3>
        {learningPathName && (
          <h4 className="truncate text-xs font-normal text-gray-700">
            {t("learning-paths.label")} - {learningPathName}
          </h4>
        )}
        {(displayInfos || displayVersion) && (
          <Infos
            displayInfos={displayInfos}
            displayVersion={displayVersion}
            version={version}
            numberOfLessons={numberOfLessons}
            totalTime={totalTime}
          />
        )}
      </div>
      <div>
        {displayDescription && description && (
          <p
            title={description}
            className="line-clamp-3 text-sm text-gray-500"
            dangerouslySetInnerHTML={{ __html: description.replace(/(?:\r\n|\r|\n)/g, "<br />") }}
          ></p>
        )}
        {displayTags && tags && tags.length > 0 && (
          <div className="mt-1 flex flex-wrap space-x-1">{tags}</div>
        )}
      </div>
    </div>
  </>);
}

function CourseItemListBody({
  id,
  title,
  description,
  img,
  version,
  totalTime,
  numberOfLessons,
  tags,
  deleteCourse,
  learningPathName
}: CourseItemProps["course"] & { deleteCourse?: DeleteCourse }) {
  const { t } = useTranslation();
  const {
    opts: { displayDescription, displayVersion, displayInfos, displayTags }
  } = useContext(CourseViewModeContext);

  return (
    (<div className="flex items-center px-4 py-4 sm:px-6">
      <div className="flex min-w-0 flex-1 space-x-4">
        <div className="shrink-0">
          <CourseImg
            id={id}
            image={img}
            className="course-list-item-img !w-32 rounded-md border border-gray-100 object-cover"
          />
        </div>
        <div className="flex flex-auto flex-col justify-between">
          <div>
            <h3 title={title} className="truncate font-medium text-gray-900">
              {title}
            </h3>
            {learningPathName && (
              <h4 className="truncate text-xs font-normal text-gray-700">
                {t("learning-paths.label")} - {learningPathName}
              </h4>
            )}
            {(displayInfos || displayVersion) && (
              <Infos
                displayInfos={displayInfos}
                displayVersion={displayVersion}
                version={version}
                numberOfLessons={numberOfLessons}
                totalTime={totalTime}
              />
            )}
          </div>
          <div>
            {description && displayDescription && (
              <p
                title={description}
                className="mt-3 line-clamp-3 text-sm text-gray-500"
                dangerouslySetInnerHTML={{ __html: description.replace(/(?:\r\n|\r|\n)/g, "<br />") }}
              ></p>
            )}
            {displayTags && tags && tags.length > 0 && (
              <div className="mt-1 flex flex-wrap space-x-1">{tags}</div>
            )}
          </div>
        </div>
      </div>
      {deleteCourse && (
        <div>
          <Tooltip title={deleteCourse.label}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                deleteCourse.cb();
              }}
              className="mx-2.5"
              size="xs"
              icon={<TrashIcon className="text-red-500" />}
            />
          </Tooltip>
        </div>
      )}
      <div>
        <ChevronRightIcon className="h-5 w-5 text-gray-400" />
      </div>
    </div>)
  );
}

interface InfosProps {
  displayInfos: boolean;
  displayVersion: boolean;
  totalTime?: string;
  numberOfLessons?: number;
  version: number;
}

const Infos = ({ displayInfos, displayVersion, totalTime, numberOfLessons, version }: InfosProps) => {
  const { t } = useTranslation();
  return (
    <div className="mt-1 flex flex-wrap items-center text-sm text-gray-500">
      {displayInfos && (
        <>
          {totalTime && (
            <>
              <div className="mr-2 flex items-center">
                <ClockIcon className="mr-1 h-5 w-5 text-gray-400" />
                {totalTime}
              </div>
            </>
          )}
          <div className="flex items-center">
            <BookOpenIcon className="mr-1 h-5 w-5 text-gray-400" />
            {t("courses.amount-lesson", { count: numberOfLessons })}
          </div>
        </>
      )}

      {displayVersion && (
        <>
          {displayInfos && <>・</>}
          <p className="text-sm text-primary-500">
            {t("courses.version")} {version}
          </p>
        </>
      )}
    </div>
  );
};
