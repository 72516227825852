import { useQuery } from "@tanstack/react-query";

import { http } from "~/lib/http";

import type { Features } from "~/auth/types";

interface Org {
  id: number;
  identifier: string;
  logo: string | null;
  backgroundImg: string | null;
  tagline: string | null;
  features: Features[];
}

export function useCurrentOrg() {
  return useQuery({
    queryKey: ["current-organization"],
    queryFn: async ({ signal }) => {
      const identifier = window.location.hostname.split(".")[0];
      if (identifier) {
        // @Get("/element/:identifier/identifier")
        return http.get(`element/${identifier}/identifier`, { signal }).json<Org>();
      }
      return null;
    },
    retry: false,
    staleTime: 1000 * 60 * 30 // 30min
  });
}
