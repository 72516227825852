import React from "react";
import cx from "classnames";

import { safeHtmlString } from "./utils";

export interface ContentEditableProps {
  className?: string;
  value?: string;
  onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void;
}

export const ContentEditable = React.forwardRef<HTMLDivElement, ContentEditableProps>(
  function ContentEditable({ className, value = "", onBlur, onFocus }, ref) {
    return (
      <div
        ref={ref}
        spellCheck={false}
        className={cx("focus:outline-none", {
          [className]: className
        })}
        contentEditable={true}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
        dangerouslySetInnerHTML={{ __html: safeHtmlString(value) }}
      />
    );
  }
);
