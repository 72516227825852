export function matchReducer<A extends { type: number }, T = unknown>(
  actionType: number,
  reducers: Record<number, (state: T, action: A) => T>,
  state: T,
  action: A
): T {
  if (reducers[actionType]) {
    const returnValue = reducers[actionType];
    return returnValue(state, action);
  }

  const error = new Error(
    `Aucun handler pour "${actionType}". Handlers disponibles: ${Object.keys(reducers)
      .map((key) => `"${key}"`)
      .join(", ")}.`
  );
  if (Error.captureStackTrace) Error.captureStackTrace(error, matchReducer);
  throw error;
}
