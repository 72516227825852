import { createContext, useContext, useCallback, useState, useEffect } from "react";

import { ProgressBar } from "./bar";

export function GlobalProgressBar({ children }: React.PropsWithChildren<{}>) {
  const [state, setState] = useState(false);

  const show = useCallback(() => setState(true), []);
  const hide = useCallback(() => setState(false), []);

  return (
    <>
      <ProgressBar show={state} />
      <GlobalProgressBarContext.Provider
        value={{
          isDisplayed: state,
          show,
          hide
        }}
      >
        {children}
      </GlobalProgressBarContext.Provider>
    </>
  );
}

type GlobalProgressBarContextType = {
  isDisplayed: boolean;
  show: () => void;
  hide: () => void;
};

const GlobalProgressBarContext = createContext<GlobalProgressBarContextType | null>(null);

function useGlobalProgressBarContext() {
  return useContext(GlobalProgressBarContext);
}

function GlobalProgressBarDisplay() {
  const { show, hide, isDisplayed } = useGlobalProgressBarContext();

  useEffect(() => {
    if (!isDisplayed) show();

    return () => {
      hide();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

GlobalProgressBar.Context = GlobalProgressBarContext;
GlobalProgressBar.useGlobalProgressBarContext = useGlobalProgressBarContext;
GlobalProgressBar.Display = GlobalProgressBarDisplay;
